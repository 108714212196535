<script setup lang="ts">
import { Role } from "~/types/role";
import type { SpecificationCard } from "~/types/specification.js";
import type { EmblaPluginType } from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from '@/components/ui/carousel'

const { locale } = useI18n()
const userProfile = useUserProfileStore()

const props = defineProps({
    items: {
        type: Array as PropType<SpecificationCard[]>,
        required: true,
    },
    role: {
        type: String,
    },
    has_arrows: {
        type: Boolean,
        default: false,
    }, 
    has_autoplay: {
        type: Boolean,
        default: false
    }
})

const current_path = computed(() => {
    if (userProfile.Role === Role.MASTER) return 'tasks'
    return 'catalog'
})

const carouselContainerRef = ref<InstanceType<typeof Carousel> | null>(null)

const autoplay = Autoplay({
    delay: 2000,
    stopOnMouseEnter: true,
    stopOnInteraction: false,
})

const plugins = ref<EmblaPluginType[]>([])
const key = ref(1)
const direction = computed(() => locale.value === 'ar' ? 'rtl' : 'ltr')

if(props.has_autoplay){
    plugins.value.push(autoplay)
}

watch(locale, (newLocale) => {
    nextTick(() => {
        //carouselContainerRef.value?.carouselApi?.destroy()
        carouselContainerRef.value?.carouselApi?.reInit(
            {
                direction: locale.value === 'ar' ? 'rtl' : 'ltr'
            },
        )
        autoplay.reset()
        //key.value++
    })
});

</script>

<template>
    <UiCarousel 
        :key="key"
        ref="carouselContainerRef"
        :opts="{
            align: 'center',
            loop: false,
            direction: direction
        }"
        :plugins="plugins"
        :class="{
            'with_arrows': has_arrows
        }">
        <UiCarouselContent class="-ml-7">
            <UiCarouselItem v-for="item in items" :key="item.id" class="basis-1/9 pl-7">
                <NuxtLink :to="`/${current_path}/category-${item.id}`">
                    <UiCard
                        class="border-0 bg-opacity-0 shadow-none ys-category-box-element cursor-pointer">
                            <div class="mx-auto border border-zinc-300 zoom-image ys-category-small-img-box flex justify-center items-center my-2 bg-[#F6F8FA]">
                                <NuxtImg class="ys-category-small-img min-h-full min-w-full" :src="item.small_image" :alt="item.name" placeholder="/img_placeholder.svg" />
                            </div>
                            <p class="font-medium text-center line-clamp-3">{{ item.name }}</p>
                    </UiCard>
                </NuxtLink>
            </UiCarouselItem>
        </UiCarouselContent>

        <template v-if="has_arrows">
            <UiCarouselPrevious class="categories_carousel__nav_btn w-[48px] h-[48px] border-0 bg-stroke hover:bg-gray-300 prev"/>
            <UiCarouselNext class="categories_carousel__nav_btn w-[48px] h-[48px] border-0 bg-stroke hover:bg-gray-300 next"/>
        </template>
        
    </UiCarousel>
</template>

<style scoped lang="scss">
.with_arrows{
    max-width: calc(100% - 96px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
}

.zoom-image {
    overflow: hidden;

    img {
        transition: transform 0.3s ease;
        transform: scale(1.1);

        &:hover{
            transform: scale(1);
        }
    }
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ys-category-box-element {
    font-size: 14px;
}

.ys-category-small-img-box {
    width: 76px;
    height: 76px;
    border-radius: 20px;
}

.ys-category-small-img {
    object-fit: cover;
}
</style>